





































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import {
  ElementType,
  SelectItem,
  Seminar,
  SeminarBlock,
  SeminarElement,
  SeminarOrder,
} from "@/core/models";
import { elementTypes, emptyElement, typeName } from "@/core/utils/seminars";

import Element from "./Element.vue";
import BlockDialog from "./BlockDialog.vue";
import ElementDialog from "./ElementDialog.vue";

@Component({ components: { Element, ElementDialog, BlockDialog } })
export default class AdminBlock extends Vue {
  @Prop({ default: () => undefined }) block?: SeminarBlock;
  @Prop({ default: () => undefined }) seminar?: Seminar;

  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Getter("seminars/order") order!: SeminarOrder;
  @Action("seminars/reorder") reorder!: (o: SeminarOrder) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // block stuff
  typeToString = typeName;
  dialog = false;
  addLoading = false;
  editLoading = false;
  deleteLoading = false;
  tempBlock: SeminarBlock | null = null;
  openDialog() {
    if (!this.block) return;
    this.dialog = true;
    this.tempBlock = JSON.parse(JSON.stringify(this.block));
  }

  // add element stuff
  tempElement: SeminarElement | null = null;
  elementDialog = false;
  get types(): SelectItem<ElementType>[] {
    return elementTypes.map(x => ({
      text: this.$t(`seminars.${x}.title`).toString(),
      value: x as ElementType,
    }));
  }
  openAddElement(type: ElementType) {
    if (!this.seminar || !this.block) return;
    this.elementDialog = true;
    this.tempElement = {
      ...emptyElement(type),
      seminarId: this.seminar.id,
      blockId: this.block.id,
    };
  }

  moveDialog = false;
  selectedPosition = -2;
  cancelMove() {
    this.selectedPosition = -2;
    this.moveDialog = false;
  }
  confirmMove() {
    const newOrder = JSON.parse(JSON.stringify(this.order)) as SeminarOrder;
    if (!newOrder) return;

    // get new index
    const newIndex = this.selectedPosition + 1;
    const idx = newOrder.blocks.findIndex(x => x.blockId === this.block?.id);
    if (idx === -1) return;
    newOrder.blocks[idx].blockIndex = newIndex;
    console.debug("Moving", this.block?.id, "to", newIndex);

    // push blocks back
    const blocks = newOrder.blocks.sort((a, b) => a.blockIndex - b.blockIndex);
    console.debug(JSON.parse(JSON.stringify(blocks)));
    for (let i = newIndex; i < blocks.length; i++)
      if (blocks[i].blockId !== this.block?.id) blocks[i].blockIndex += 1;

    blocks.sort((a, b) => a.blockIndex - b.blockIndex);
    console.debug(JSON.parse(JSON.stringify(blocks)));

    // fix blocks where pos diff > 1
    for (let i = 0; i < blocks.length - 1; i++) {
      const curr = blocks[i];
      const next = blocks[i + 1];
      if (next.blockIndex - curr.blockIndex > 1)
        blocks[i + 1].blockIndex = curr.blockIndex + 1;
    }
    console.debug(JSON.parse(JSON.stringify(blocks)));

    // put blocks in
    newOrder.blocks = blocks;

    // send to api
    this.reorder(newOrder);

    // reset
    this.moveDialog = false;
    this.selectedPosition = -2;
  }
}
